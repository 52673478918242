<template>
  <div class="w-full py-2">
    <div class="flex w-full p-4 mt-4">
      <img class="mr-3" src="../../../../../../assets/images/icon_caja.svg" alt="">
      <p class="text-xl text-blue-800 font-bold">Gestión logística</p>
    </div>
    <div class="mt-2 w-full">
      <div class="card" role="group">
        <TabMenu :model="items" v-model:activeIndex="active"/>
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { can as $c } from '../../../../../../utils/permission'
import { menuCollapse } from '@/utils/collapseMenu'
export default {
  name: 'index',
  setup () {
    const active = ref(0)
    const itemsTab = ref(
      [
        {
          label: 'Listado de envíos realizados',
          icon: 'pi pi-check-circle',
          to: 'listar-control-envios-realizados',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.configuracion']
        },
        {
          label: 'Listado de envíos pendientes',
          icon: 'pi pi-tags',
          to: 'listar-control-envios-pendientes',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.configuracion']
        }
      ]
    )
    const items = computed(() => {
      return itemsTab.value.filter((x) => x.permiso.every(a => $c(a)))
    })

    onMounted(() => {
      menuCollapse()
    })
    return {
      items,
      active
    }
  }
}
</script>

<style scoped lang="scss">
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #1c3faa;
  color: #1c3faa;
}
::v-deep(.p-tabmenu-nav){
  display: -webkit-box !important;
}
</style>
